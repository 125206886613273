import React from "react"
import HomeVideo from "../components/homevideo"
import MobileHome from "../components/mobileHome"
import HeaderDecider from "../components/headerDecider"
import { connect } from "react-redux"

const IndexPage = (props) => { 

  let mainElem;

  if (props.width < 768) {

    mainElem = <MobileHome />

  } else if (props.width >= 768) {

    mainElem = <HomeVideo />

  }

  return [  

    <HeaderDecider key={`header decider`} />,
    <div>{mainElem}</div>
      
  ]
  
}

const mapStateToProps = (state) => {
  return {
      width: state.width,
      height: state.height,
  }
}

export default connect(mapStateToProps)(IndexPage)

//export default IndexPage
