import React, { useState} from "react"
import Footer from "../components/footer"
import { connect } from "react-redux"
import pic1 from "../images/mob/home/home1.jpg" 
import pic2 from "../images/mob/home/home2.jpg"
import pic3 from "../images/mob/home/home3.jpg"
import pic4 from "../images/mob/home/home4.jpg"
import pic5 from "../images/mob/home/home5.jpg"
import pic6 from "../images/mob/home/home6.jpg"
import pic7 from "../images/mob/home/home7.jpg"
import pic8 from "../images/mob/home/home8.jpg"

const MobileHome = props => {

    const picStyle = {
        width: props.width,
        height: `100%`,
        position: `fixed`,
        overflow: `hidden`
    }
    
    const picCont = {
        position: `relative`,
    }

    const pics = [pic1, pic2, pic3, pic4, pic5, pic6, pic7, pic8]

    const [pic, change] = useState(pics[0])

    const [chosen, changeIndex] = useState(0)

    const [startC, startCoords] = useState()

    const [changeC, changeCoords] = useState()

    const clicked = event => {

        event.preventDefault()

        changeIndex(event.target.id)

        change(pics[event.target.id])
        
    }

    const moveStart = event => {

        startCoords(event.targetTouches[0].clientX)

    }

    const moveMove = event => {

        changeCoords(event.targetTouches[0].clientX)                           
        
    }

    const moveEnd = event => {

        const diff = startC - changeC

        if ( diff > 0 && diff >= 50 ) {

            if (chosen === 0) {
                
                changeIndex(7)
                change(pics[7])
            } else {
                changeIndex(chosen - 1)
                change(pics[chosen - 1])
            }
            
        } else {

            if (chosen === 7) {

                changeIndex(0)
                change(pics[0])

            } else {

                changeIndex(chosen + 1)
                change(pics[chosen + 1])
            }
            
        }    

    }

    return [

        <div style={picCont}>
            <img onTouchStart={moveStart} onTouchMove={moveMove} onTouchEnd={moveEnd} src={pic} alt={pic} style={picStyle}/>
        </div>,
        
        <Footer key={chosen} clickedIndex={chosen} length={pics.length} clicky={clicked} />
    ]

}

const mapStateToProps = (state) => {
    return {
        width: state.width,
        height: state.height
    }
  }
  
export default connect(mapStateToProps)(MobileHome)

