import React, { useState} from "react"
import Footer from "../components/footer"
import { connect } from "react-redux"

const vid1 = `https://player.vimeo.com/external/363862050.hd.mp4?s=a960ded89038e20d9363d20cded61689e2edea60&profile_id=175` //bosque
const vid2 = `https://player.vimeo.com/external/363863611.hd.mp4?s=d654c8bd1dce56563fa4779eda0a9d9ef9f4193f&profile_id=175` //raquel
const vid3 = `https://player.vimeo.com/external/363863053.hd.mp4?s=6aa7f718701e6155bd0c94ff53e06e2c50f7f937&profile_id=175` //natalia
const vid4 = `https://player.vimeo.com/external/366031216.hd.mp4?s=3c3b5a6c8d3f6a401a580049cb013b1fb6c7d76d&profile_id=175` //brasil
const vid5 = `https://player.vimeo.com/external/363862904.hd.mp4?s=8acd8b968c51ce3508ea7abde2bafe192e90e40f&profile_id=175` //bukowski
const vid6 = `https://player.vimeo.com/external/363863735.hd.mp4?s=bb783f7da95c853cae3c9fc352d64f6f0f36182c&profile_id=175` //yoha
const vid7 = `https://player.vimeo.com/external/363861904.hd.mp4?s=67bf92329b36074c73226ba343c449b5e0deefc9&profile_id=175` //baxin

const HomeVideo = props => {

  let vidStyle = {
    flex: `none`
  }

  let vidCont = {

    display: `flex`,
    justifyContent: `center`,
    height: props.height,
    overflow: `hidden`,

  }

  if (props.width > 1240) {

    vidCont = {
      width: props.width
    }

    vidStyle = {
      width: props.width
    }

  }

  const vids = [vid1, vid2, vid3, vid4, vid5, vid6, vid7]

  const [vid, change] = useState(vids[0])

  const [chosen, changeIndex] = useState(0)

  const clicked = event => {
    event.preventDefault()

    changeIndex(event.target.id)

    change(vids[event.target.id])
        
  }

  return [ 

    <div style={vidCont}>
      <video key={vid} rel="preconnect" style={vidStyle} muted={props.mute} src={vid} loop autoPlay />,
    </div>,
    <Footer key={chosen} clickedIndex={chosen} length={vids.length} clicky={clicked} /> 
            
  ]

}

const mapStateToProps = (state) => {
    return {
        width: state.width,
        height: state.height,
        mute: state.mute
    }
  }
  
export default connect(mapStateToProps)(HomeVideo)

