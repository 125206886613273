import React from "react"
import Empty from "../images/empty.png"
import Full from "../images/full.png"

const Footer = props => {

    const rend = []

    for (let i=0; i < props.length; i++) {

        if (props.clickedIndex == i) {

            let img = <img className="homeDots" key={i} id={i} onClick={props.clicky} src={Empty} alt="emptyFooter" />
            rend.push(img)

        } else {

            let img = <img className="homeDots" key={i} id={i} onClick={props.clicky} src={Full} alt="emptyFooter" />
            rend.push(img)

        }
        
    }
    
    return (

        <div className="footer">
            {rend}
        </div>

    )

}

export default Footer